<template>
  <side-bar>
    <template v-slot:sidebarItems>
      <div
        class="font-weight-bolder bg-primary text-white mb-5 row p-2"
        id="loggedAs"
      >
        <div class="col-3" id="avatar">
          <span
            class="avatar avatar-sm rounded-circle bg-secondary text-default"
            ><em class="fa fa-user"></em></span
          >
        </div>
        <div class="col-9 mt-1" id="words">
          <small>{{ names }}</small>
        </div>
      </div>
      <sidebar-items></sidebar-items>
    </template>
  </side-bar>
  <!-- Main content -->
  <div class="main-content" id="panel">
    <!-- Topnav -->
    <nav
      aria-label="nav"
      class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom"
    >
      <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="h4 text-white">
             <img src="@/assets/images/logo2.jpeg" width="50" height="50"  class="rounded" />
          </div>
          <!-- Navbar links -->
          <ul 
            class="navbar-nav navbar-dark bg-default align-items-center ml-md-auto"
          >
            <li class="nav-item d-xl-none ml-5">
              <!-- Sidenav toggler -->
              <div
                class="pr-3 sidenav-toggler sidenav-toggler-dark"
                data-action="sidenav-pin"
                data-target="#sidenav-main"
              >
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </div>
            </li>
            <li v-if="false" class="nav-item dropdown">
              <a
                class="nav-link"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ni ni-bell-55"></i>
              </a>
              <div
                class="dropdown-menu dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
              >
                <!-- Dropdown header -->
                <div class="px-3 py-3">
                  <h6 class="text-sm text-muted m-0">
                    You have
                    <strong class="text-primary">13</strong> notifications.
                  </h6>
                </div>
                <!-- List group -->
                <div class="list-group list-group-flush">
                  <a href="#!" class="list-group-item list-group-item-action">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <!-- Avatar -->
                        <img
                          alt="Image placeholder"
                          src=""
                          class="avatar rounded-circle"
                        />
                      </div>
                      <div class="col ml--2">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <h4 class="mb-0 text-sm">John Snow</h4>
                          </div>
                          <div class="text-right text-muted">
                            <small>2 hrs ago</small>
                          </div>
                        </div>
                        <p class="text-sm mb-0">
                          Let's meet at Starbucks at 11:30. Wdyt?
                        </p>
                      </div>
                    </div>
                  </a>
                  <a href="#!" class="list-group-item list-group-item-action">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <!-- Avatar -->
                        <div class="avatar rounded-circle">
                          <span>{{ names[0] }}</span>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <h4 class="mb-0 text-sm">{{ names[0] }}</h4>
                          </div>
                          <div class="text-right text-muted">
                            <small>3 hrs ago</small>
                          </div>
                        </div>
                        <p class="text-sm mb-0">
                          A new issue has been reported for Argon.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- View all -->
                <a
                  href="#!"
                  class="dropdown-item text-center text-primary font-weight-bold py-3"
                  >View all</a
                >
              </div>
            </li>
            <li v-if="false" class="nav-item dropdown">
              <a
                class="nav-link"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ni ni-ungroup text-white"></i>
              </a>
              <div
                class="dropdown-menu dropdown-menu-lg dropdown-menu-dark bg-white text-black-50 dropdown-menu-right"
              >
                <div class="row shortcuts px-4">
                  <a href="#!" class="col-4 shortcut-item text-dark">
                    <span
                      class="shortcut-media avatar rounded-circle bg-gradient-info"
                    >
                      <i class="ni ni-credit-card"></i>
                    </span>
                    <small>Payments</small>
                  </a>
                  <a href="#!" class="col-4 shortcut-item text-dark">
                    <span
                      class="shortcut-media avatar rounded-circle bg-gradient-green"
                    >
                      <i class="ni ni-books"></i>
                    </span>
                    <small>Disputes</small>
                  </a>
                  <a href="#!" class="col-4 shortcut-item text-dark">
                    <span
                      class="shortcut-media avatar rounded-circle bg-gradient-purple"
                    >
                      <i class="ni ni-pin-3"></i>
                    </span>
                    <small>Orders</small>
                  </a>
                  <a href="#!" class="col-4 shortcut-item text-dark">
                    <span
                      class="shortcut-media avatar rounded-circle bg-gradient-yellow"
                    >
                      <i class="ni ni-basket"></i>
                    </span>
                    <small>Shop</small>
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <ul class="navbar-nav navbar-dark align-items-center ml-auto ml-md-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link pr-0"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle" style="width:50px;height:50px; background-color:#5e72e4;">
                    <strong style="color:white;font-size: 20px;">{{ names[0] }}</strong>
                  </span>
                  <div class="media-body ml-2 d-none d-lg-block">
                    <span class="mb-0 text-sm font-weight-bold">{{
                      names
                    }}</span>
                  </div>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-header noti-title">
                  <h6 class="text-overflow m-0">Welcome!</h6>
                </div>
                <router-link :to="{ name: 'UserProfile' }" class="dropdown-item">
                    <a href="#!" c>
                      <i class="ni ni-single-02"></i> <span></span>
                      <span>My profile</span>
                    </a>
                </router-link>
                <router-link :to="{ name: 'Products' }" class="dropdown-item" v-if="!$guards.is_super_admin() && !$guards.is_Admin()">
                    <a href="#!" >
                      <i class="fa fa-braille"></i> <span></span>
                      <span>My Products</span>
                    </a>
                </router-link>

                <a  @click="logout" class="dropdown-item">
                  <i class="ni ni-user-run"></i>
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- Page content -->
    <div class="container-fluid mt-3">
      <div class="header py-0 shadow bg-white mt-3">
        <div class="container-fluid">
          <div class="header-body">
            <div class="row py-3">
              <nav aria-label="breadcrumb" class="ml-2">
                <ol class="breadcrumb breadcrumb-links">
                  <li class="breadcrumb-item">
                    <a href="#"><i class="fas fa-home"></i></a>
                  </li>
                  <li class="breadcrumb-item"><a href="#">Page</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                   {{ $route.name }}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100">
        <router-view v-slot="{ Component }">
          <transition name="slide-fade">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../components/SideBar";
import SidebarItems from "./partials/CustomerSideItems";
export default {
  components: {
    "side-bar": SideBar,
    "sidebar-items": SidebarItems,
  },
  computed: {
    names: function () {
      return this.$store.getters.getNames;
    },
    first_name: function () {
      return this.$store.getters.getFirstName;
    },
    isSuperUser: function () {
      return this.$store.getters.getUserProfile.is_superuser;
    },
    entity_name: function () {
      if (!this.$store.getters.getUserProfile.is_superuser)
        return this.$store.getters.getUserProfile.entity.name;
      else return "";
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style>
</style>