<template>
  <li class="nav-item active">
    <a
      @click="makeActive"
      v-if="isParent"
      :class="nav_class"
      :href="`#${id}`"
      data-toggle="collapse"
      role="button"
      aria-expanded="false"
      :aria-controls="id"
      :id="id"
    >
      <em :class="iconClass"></em>&nbsp; 
      <span class="sidenav-normal"> {{ text }} </span>
    </a>
    <a :id="id"  @click="makeActive" v-else :class="nav_class" href="#">
      <em :class="iconClass"></em>&nbsp;
      <span class="sidenav-normal"> {{ text }} </span>
    </a>
    <div v-if="isParent" class="collapse" :id="id">
      <ul class="nav nav-sm flex-column">
        <SidebarItem
          v-for="child in children"
          :key="child.id"
          :id="child.id"
          :icon_class="child.icon_class"
          :text="child.text"
          :link="child.link"
          :children="child.children"
          :isParent="child.isParent"
        />
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "SidebarItem",
  props: ["id", "iconClass", "text", "link", "children", "isParent"],
  data(){
      return{
          nav_class:'nav-link'
      }
  },
  methods:{
      makeActive: function(e){
          var this_obj=this
          e.preventDefault()
          var elms=document.getElementsByClassName('nav-link')
          Array.prototype.forEach.call(elms,function (el){
            if(el.getAttribute('id') != this_obj.id)
              el.classList.remove('active')
          })
          document.getElementById(this.id).classList.add('active')
          if(!this.isParent){
            this.$router.push(this.link)
          }

      }
  }
};
</script>

<style>
</style>