<template>
  <sidebar-item
    id="Home"
    iconClass="fa fa-home text-primary"
    text="Home"
    link="/"
    :children="[]"
    :isParent="false"

  />
   <sidebar-item
    id="products"
    iconClass="ni ni-ungroup text-blue"
    text="Products"
    link="/products"
    :children="[]"
    :isParent="false"
    v-if="$guards.is_logged_in()"
  />

  <sidebar-item
      id="entities"
      iconClass="ni ni-ungroup text-blue"
      text="Businesses"
      v-if="$guards.is_super_admin() || $guards.is_Admin()"
      link="/entities/"
      :children="[]"
      :isParent="false"
  />

  <sidebar-item
      id="categories"
      iconClass="ni ni-ungroup text-blue"
      text="Categories"
      v-if="$guards.is_super_admin() || $guards.is_Admin()"
      link="/categories/"
      :children="[]"
      :isParent="false"
  />

  <sidebar-item
    id="my_business"
    iconClass="ni ni-ungroup text-blue"
    text="My Business Profile"
    v-if="!$guards.is_super_admin() && !$guards.is_Admin() && $guards.is_logged_in()"
    :link="'/entity/detail/' + entity_uuid"
    :children="[]"
    :isParent="false"
  />
 
  

<!-- Super admin menu -->
 

  <sidebar-item
    id="posts"
    iconClass="ni ni-ungroup text-blue"
    v-if="$guards.is_super_admin() || $guards.has_role('Admin')"
    text="Ads"
    link="/resources/carouselsItems/"
    :children="[]"
    :isParent="false"
  />
  <sidebar-item
      id="about"
      iconClass="ni ni-ungroup text-blue"
      text="About"
      v-if="$guards.is_super_admin() || $guards.has_role('Admin')"
      link="/about/"
      :children="[]"
      :isParent="false"
  />


   <sidebar-item
    id="profile"
    iconClass="fa fa-user text-blue"
    text="Sign-In Settings"
    link="/user/profile"
    :children="[]"
    :isParent="false"
     v-if="!$guards.is_super_admin() && $guards.is_logged_in()"
  />




</template>

<script>
import SideBarItem from "../../components/SidebarItem";

export default {
  components: {
    "sidebar-item": SideBarItem,
  },
  computed:{
    entity_uuid:function(){
      if(this.$store.getters.getUserProfile && this.$store.getters.getUserProfile.entity)
        return this.$store.getters.getUserProfile.entity.uuid
      return '' 
    }
  }
};
</script>

<style>
</style>